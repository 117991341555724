import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

import { Helmet } from "react-helmet";
import Page from "../../src/components/Page";
import PropTypes from "prop-types";
import React from "react";
import favicon from "../../src/svg/favicon.ico";
import logo from "../../src/svg/logo.svg";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>TalentDriver</title>
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content="Transportation Management System" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta property="og:title" content="TalentDriver" />
        <meta
          property="og:description"
          content="Transportation Management System"
        />
        <meta property="og:url" content="https://talentdriver.co/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page>{props.children}</Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node
};
